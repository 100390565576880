import React from 'react';
import { createRoot } from 'react-dom/client';
import { ReactLocation, Router, Outlet } from 'react-location';
import { rankRoutes } from 'react-location-rank-routes';
import { useTranslation } from 'react-i18next';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { ToastContainer } from 'react-toastify';
import usePaymentStore, { setPaymentStateSelector } from 'Core/stores/payment';
import Loader from 'Core/components/Loader';
import DialogContainer from 'Core/components/Dialog/Container';
import { ErrorBoundary } from 'Core/components/ErrorBoundary';
import { getAppConfig } from 'Core/config/appConfig';
import { routes } from './routes';
import './i18n';
import 'react-toastify/dist/ReactToastify.css';

const stripePromise = loadStripe(getAppConfig().stripePublicKey);
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      staleTime: 5 * 60 * 1000,
      cacheTime: 10 * 60 * 1000,
      refetchOnWindowFocus: false,
    },
  },
});

const location = new ReactLocation();

function PaymentOfficeApp() {
  const { t } = useTranslation();
  const fallback = <Loader message={t('status.loading.component')} />;

  const setPaymentState = usePaymentStore(setPaymentStateSelector);
  setPaymentState({ stripePromise });

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <DialogContainer />
      <ToastContainer
        position="top-right"
        autoClose={4000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        limit={3}
        theme="colored"
      />
      <ErrorBoundary>
        <React.Suspense fallback={fallback}>
          <Elements stripe={stripePromise} options={{ locale: getAppConfig().stripeLocale }}>
            <Router
              location={location}
              routes={routes[getAppConfig().country]}
              filterRoutes={rankRoutes}
            >
              <Outlet />
            </Router>
          </Elements>
        </React.Suspense>
      </ErrorBoundary>
    </QueryClientProvider>
  );
}

createRoot(document.getElementById('app-container')).render(
  <React.Suspense fallback={<Loader message="Loading translations" />}>
    <PaymentOfficeApp className="font-poppins" />
  </React.Suspense>
);
