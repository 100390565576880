import React from 'react';
import Confirm from './Confirm';
import Portal from 'Core/components/Portal';
import useDialogStore from 'Core/stores/dialog';

export default function DialogContainer() {
  const dialog = useDialogStore();

  const handleConfirm = () => {
    dialog.onConfirm();
    if (typeof dialog.confirmCallback === 'function') {
      dialog.confirmCallback();
    }
  };

  const handleCancel = () => {
    dialog.onCancel();
    if (typeof dialog.cancelCallback === 'function') {
      dialog.cancelCallback();
    }
  };

  if (dialog.open && dialog.type === 'confirm') {
    return (
      <Portal>
        <div className="z-50 inset-0 fixed items-center justify-center flex [background:rgba(155,155,155,0.5)] [backdrop-filter:blur(2px)]">
          <Confirm
            title={dialog.title}
            description={dialog.description}
            confirmButtonLabel={dialog.confirmButtonLabel}
            cancelButtonLabel={dialog.cancelButtonLabel}
            onConfirm={handleConfirm}
            onCancel={handleCancel}
          />
        </div>
      </Portal>
    );
  }

  return '';
}
