import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Locize from 'i18next-locize-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { getAppConfig } from 'Core/config/appConfig';
import { formatCurrency } from 'Core/utils/numberFormat';

i18n
  .use(Locize)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    backend: {
      projectId: process.env.LOCIZE_PROJECT_ID,
      apiKey: process.env.LOCIZE_API_KEY,
      referenceLng: 'en-US',
      allowedAddOrUpdateHosts: ['localhost'],
    },
    lng: getAppConfig().locale,
    fallbackLng: 'en-US',
    debug: !process.env.LOCIZE_DEBUG,
    interpolation: {
      escapeValue: false,
      format: (data, format) => {
        switch (format) {
          case 'formatCurrency':
            return formatCurrency(data);
          default:
            return data;
        }
      },
    },
    nsSeparator: false,
    keySeparator: false,
    defaultNS: 'default',
    saveMissing: process.env.LOCIZE_SAVE_MISSING_KEYS,
  });

export default i18n;
