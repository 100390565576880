import { routes as coreRoutes } from 'Core/routes';
import { routes as depositRoutes } from 'Modules/deposit/routes';
import { routes as directSalesRoutes } from 'Modules/directSales/routes';
import { routes as creditDebitRoutes } from 'Modules/creditDebit/routes';
import { routes as cardPaymentRoutes } from 'Modules/cardPayment/routes';
import { routes as financingRoutes } from 'Modules/financing/routes';

export const routes = {
  us: [
    ...coreRoutes,
    ...depositRoutes,
    ...directSalesRoutes,
    ...creditDebitRoutes,
    ...cardPaymentRoutes,
    ...financingRoutes,
  ],
  ca: [...coreRoutes, ...depositRoutes],
};
