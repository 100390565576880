import React from 'react';
import { getWithExpiry, setWithExpiry } from 'Core/utils/storage';

const ErrorFallback = ({ error }) => {
  // Handles failed lazy loading of a JS/CSS chunk.
  React.useEffect(() => {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    if (error?.message && chunkFailedMessage.test(error.message)) {
      if (!getWithExpiry('chunk_failed')) {
        setWithExpiry('chunk_failed', 'true', 10000);
        window.location.reload();
      }
    }
  }, [error]);

  return (
    <div className="flex flex-col items-center justify-center mt-32">
      <h2>
        There is a new version of the application available. Please reload the page to get the
        latest version.
      </h2>
      <button
        className="bg-primary text-white rounded border py-2 px-4 mt-4"
        onClick={() => window.location.reload()}
      >
        Reload Page
      </button>
      <pre>{error?.message}</pre>
    </div>
  );
};

export default ErrorFallback;
