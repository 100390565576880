import React from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';

function Portal({ children }) {
  const mount = document.getElementById('portal');
  const element = document.createElement('div');

  React.useEffect(() => {
    //mount.innerHTML = '';
    mount.appendChild(element);

    return () => mount.removeChild(element);
  }, [mount, element]);

  return createPortal(children, element);
}

Portal.propTypes = {
  children: PropTypes.any.isRequired,
};

export default Portal;
